import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication

//Calendar
//Chat
//Ecommerce

//Project

// Tasks
//Form advanced

//Crypto

//TicketsList
//Crm

//Invoice

//Mailbox

// Dashboard Analytics

// Dashboard CRM

// Dashboard Ecommerce

// Dashboard Cryto

// Dashboard Cryto

// Dashboard NFT

// Pages > Team

// File Manager

// To do
//Jobs
//API Key
const rootReducer = combineReducers({
  // public
  Layout,

});

export default rootReducer;
