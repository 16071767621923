import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";


// Import Images


const Home = () => {
  const focusContacts = () => {
    const elm = document?.getElementById("contact");
    elm?.focus({ preventScroll: true });
    elm?.scrollIntoView();
  };

  const focusServices = () => {
    const elm = document?.getElementById("services");
    elm?.focus({ preventScroll: true });
    elm?.scrollIntoView();
  };

  return (
    <React.Fragment>
      <section
        className="section pb-0 hero-section py-auto"
        style={{ minHeight: "100vh" }}
        id="hero"
      >
        <div className="bg-overlay bg-overlay-pattern-home"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} sm={10}>
              <div className="text-center mt-5 pt-5">
                <h1 className="display-6 fw-bold mb-3 lh-base mt-5">
                  Todos tus procesos en un solo lugar,
                  <span style={{ color: "#b8b6fb" }}> BackApp Chile</span>
                </h1>
                <p className="lead text-muted lh-base">
                  Hacemos de tu Administración, Contabilidad y Remuneraciones
                  algo simple. Soluciones a la medida de tus necesidades
                </p>
                <div className="d-flex gap-2 justify-content-center mt-4">
                  <Link
                    to="#contact"
                    onClick={() => focusContacts()}
                    className="btn btn-warning"
                  >
                    Solicita Gratis tu Tax Review
                    <i className="ri-eye-line align-middle ms-5"></i>
                  </Link>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                  <Link
                    to="#contact"
                    onClick={() => focusContacts()}
                    className="btn btn-primary"
                  >
                    Contactanos
                    <i className="ri-arrow-right-line align-middle ms-1"></i>
                  </Link>
                  <Link
                    to="#contact"
                    onClick={() => focusServices()}
                    className="btn btn-danger"
                  >
                    Ver servicios
                    <i className="ri-eye-line align-middle ms-1"></i>
                  </Link>
                </div>
              </div>

              <div className="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                {/* <Swiper
                  spaceBetween={30}
                  effect={"fade"}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  modules={[EffectFade, Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img
                      src={defaultDemo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img src={saasDemo} className="d-block w-100" alt="..." />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img
                      src={materialDemo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img
                      src={minimalDemo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img
                      src={creativeDemo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img src={modernDemo} className="d-block w-100" alt="..." />
                  </SwiperSlide>
                  <SwiperSlide className="carousel-inner shadow-lg p-2 bg-white rounded">
                    <img
                      src={interactiveDemo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </SwiperSlide>
                </Swiper> */}
              </div>
            </Col>
          </Row>
        </Container>

        <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <g mask='url("#SvgjsMask1003")' fill="none">
              <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z"></path>
            </g>
          </svg>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
