import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth

//calendar
//chat
//ecommerce

//Project
// Task
// Crypto
//TicketsList

//crm
//invoice
//mailbox

// Dashboard Analytics

// Dashboard CRM

// Dashboard Ecommerce

// Dashboard Crypto

// Dashboard Project

// Dashboard NFT

// Pages > Team

// File Manager

// To do
//Jobs
//API Key
export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    
  ]);
}
