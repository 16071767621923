import React from "react";

// Landing Index
import OnePage from "../pages/Landing/OnePage";

// User Profile

const publicRoutes = [
  { path: "/", component: <OnePage /> },
  { path: "*", component: <OnePage /> },
];

export { publicRoutes };
