import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

// Import Images
import img1 from "../../../assets/images/landing/features/img-1.webp";
import img2 from "../../../assets/images/landing/features/img-2.png";
import logolight from "../../../assets/images/logo-light.png";

import consta1 from "./aseets/consta1.webp";
import consta2 from "./aseets/consta2.webp";
import consta3 from "./aseets/consta3.svg";

const Features = () => {
  return (
    <React.Fragment>
      <section
        className="section py-5"
        style={{ backgroundColor: "#212529" }}
        id="features"
      >
        <Container>
          <Row className="align-items-center gy-4">
            <Col lg={6} sm={7} className="mx-auto">
              <div className="px-5">
                <img
                  src={img1}
                  alt=""
                  className="img-fluid mx-auto rounded-5"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-muted">
                <div className="avatar-sm icon-effect mb-4">
                  <div className="avatar-title bg-transparent rounded-circle text-success h1">
                    <i className="ri-collage-line fs-36"></i>
                  </div>
                </div>
                <h3 className="mb-3 fs-20 text-center">
                  ¿En que consiste el servicio de Outsourcing de BackApp?
                </h3>
                <p className="mb-4 ff-secondary fs-16 text-justify">
                  Es la externalización de todos los procesos tanto como del
                  administrativo-contable, de como el remuneracional. Esto
                  consigo trae bastantes beneficios:
                </p>

                <Row className="justify-content-center">
                  <Col sm={5}>
                    <div className="vstack gap-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">
                            Aumento de la rentabilidad a través de la mejora en
                            los niveles de productividad.
                          </h5>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">
                            Utilización de recursos humanos externos (staff
                            especializado).
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-2">
                        <div className="avatar-xs icon-effect">
                          <div className="avatar-title bg-transparent text-success rounded-circle h2">
                            <i className="ri-check-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-0">
                          Optimización en los controles.
                        </h5>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="vstack gap-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">
                            Mayor flexibilidad interna maximizando la
                            administración del tiempo.
                          </h5>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">
                            Acceso a tecnología y automatización avanzada de
                            procesos.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <p className="mb-4  mt-4 ff-secondary fs-16">
                  En definitiva, nuestra propuesta de valor trabajando junto a
                  nuestros clientes para ofrecer el servicio que más se ajuste a
                  las necesidades de su empresa y lograr así los beneficios
                  reales que ofrece esta modalidad de negocios.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="section"
        style={{ backgroundColor: "#3e3c85" }}
        id="plans"
      >
        {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h3 className="mb-3 fw-semibold">Divisiones</h3>
                <p className="text-muted mb-4">
                  Impulsamos tu empresa a través de nuestras divisiones
                  fundamentales basados totalmente en Outsourcing.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="gy-4">
            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardBody className="p-4 m-2">
                  <div className="d-flex align-items-center pb-5">
                    <div className="mx-auto">
                      <div className="bg-light rounded-circle text-primary">
                        <img
                          src={consta1}
                          alt=""
                          className="img-fluid rounded-circle"
                          width={180}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="flex-grow-1">
                      <h5 className="mb-1 fw-semibold">
                        Contabilidad - Finanzas - Tributario
                      </h5>
                    </div>
                  </div>
                  <div className="py-4 text-center">
                    <div className="d-flex  text-muted">
                      <div className="flex-grow-1">
                        Registro, proceso, análisis y reporte de todas las
                        transacciones de la empresa, tanto financiera como
                        tributaria.
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardBody className="p-4 m-2">
                  <div className="d-flex align-items-center pb-5">
                    <div className="mx-auto">
                      <div className="bg-light rounded-circle text-primary">
                        <img
                          src={consta2}
                          alt=""
                          className="img-fluid rounded-circle"
                          style={{ minHeight: 180 }}
                          width={180}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="flex-grow-1">
                      <h5 className="mb-1 fw-semibold">
                        Recursos Humanos y Bienestar
                      </h5>
                    </div>
                  </div>
                  <div className="py-4 text-center">
                    <div className="d-flex  text-muted">
                      <div className="flex-grow-1">
                        Selección, gestión, formación y desarrollo profesional
                        del personal en la empresa.
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardBody className="p-4 m-2">
                  <div className="d-flex align-items-center pb-5">
                    <div className="mx-auto">
                      <div className="bg-light rounded-circle text-primary">
                        <img
                          src={consta3}
                          alt=""
                          className="img-fluid rounded-circle"
                          width={180}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="flex-grow-1">
                      <h5 className="mb-1 fw-semibold">
                        Managment (Administración o gerenciamiento)
                      </h5>
                    </div>
                  </div>
                  <div className="py-4 text-center">
                    <div className="d-flex  text-muted">
                      <div className="flex-grow-1">
                        Gestión, revisión y dirección de nuestros clientes en
                        base a los procesos administrativos de su empresa.
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <Row className="align-items-center gy-4">
            <Col lg={6} className="order-2 order-lg-1">
              <div className="text-muted">
                <h5 className="fs-12 text-uppercase text-success">
                  IAssistant
                </h5>
                <h4 className="mb-3">
                  IAssistant by BackApp - Plataforma de asistencia al cliente
                </h4>
                <p className="mb-4 ff-secondary">
                  Potenciamos y transformamos la forma en que ves cada proceso
                  de la administración de tu empresa con nuestra plataforma
                  IAssistant siempre con el apoyo de nuestros multiservicios.
                </p>

                <Row>
                  <Col sm={5}>
                    <div className="vstack gap-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Dashboard</h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Contabilidad</h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-2">
                        <div className="avatar-xs icon-effect">
                          <div className="avatar-title bg-transparent text-success rounded-circle h2">
                            <i className="ri-check-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-0">Recursos Humanos</h5>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-2">
                        <div className="avatar-xs icon-effect">
                          <div className="avatar-title bg-transparent text-success rounded-circle h2">
                            <i className="ri-check-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-0">Administración</h5>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mt-4">
                  <Link to="#" className="btn btn-primary">
                    ¡Prueba hoy mismo IAssistant by BackAppChile!
                    <i className="ri-arrow-right-line align-middle ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={6} sm={7} className="col-10 ms-auto order-1 order-lg-2">
              <div>
                <img src={img2} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
