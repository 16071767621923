import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// Import Images
import avatar2 from "../../../assets/images/users/avatar-1.jpeg";
import avatar3 from "../../../assets/images/users/avatar-3.jpeg";
import avatar10 from "../../../assets/images/users/avatar-10.jpeg";

const Team = () => {
  return (
    <React.Fragment>
      <section
        className="section "
        style={{ backgroundColor: "#3e3c85" }}
        id="team"
      >
        {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5 mt-1">
                <h3 className="mb-3 fw-semibold">
                  Nuestro <span className="text-danger">Equipo</span>
                </h3>
                {/* <p className="text-muted mb-4 ff-secondary">
                  To achieve this, it would be necessary to have uniform
                  grammar, pronunciation and more common words. If several
                  languages coalesce the grammar.
                </p> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} sm={6}>
              <Card>
                <CardBody className="text-center p-4">
                  <div className="avatar-xl mx-auto mb-4 position-relative">
                    <img
                      src={avatar2}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/in/jonathan-andres-apablaza-nieto"
                      className="btn btn-primary btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs"
                    >
                      <div className="avatar-title bg-transparent">
                        <i className="ri-linkedin-fill align-bottom"></i>
                      </div>
                    </Link>
                  </div>

                  {/* <!-- end card body --> */}
                  <h5 className="mb-1">
                    <Link to="/pages-profile" className="text-body">
                      Jonathan Apablaza
                    </Link>
                  </h5>
                  <p className="text-muted mb-0 ff-secondary">CEO - Auditor</p>
                  <p className="text-muted mb-0 ff-secondary">
                    Contabilidad y finanzas
                  </p>
                  <p className="text-muted mb-0 ff-secondary">
                    Asesorías tributarias
                  </p>
                </CardBody>
              </Card>

              {/* <!-- end card --> */}
            </Col>

            {/* <!-- end col --> */}
            <Col lg={3} sm={6}>
              <Card>
                <CardBody className="text-center p-4">
                  <div className="avatar-xl mx-auto mb-4 position-relative">
                    <img
                      src={avatar10}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <Link
                      to="https://www.linkedin.com/in/nicole-alarcon-gassiot-63a38a93/"
                      className="btn btn-primary btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs"
                      target="_blank"
                    >
                      <div className="avatar-title bg-transparent">
                        <i className="ri-linkedin-fill align-bottom"></i>
                      </div>
                    </Link>
                  </div>

                  {/* <!-- end card body --> */}
                  <h5 className="mb-1">
                    <Link to="/pages-profile" className="text-body">
                      Nicole Alarcón
                    </Link>
                  </h5>
                  <p className="text-muted mb-0 ff-secondary">
                    CEO - Ingeniera
                  </p>
                  <p className="text-muted mb-0 ff-secondary">
                    Gerenciamiento y calidad
                  </p>
                  <p className="text-muted mb-0 ff-secondary">
                    Asesorías y ejecución
                  </p>
                </CardBody>
              </Card>

              {/* <!-- end card --> */}
            </Col>

            {/* <!-- end col --> */}
            <Col lg={3} sm={6}>
              <Card>
                <CardBody className="text-center p-4">
                  <div className="avatar-xl mx-auto mb-4 position-relative">
                    <img
                      src={avatar3}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <Link
                      to="https://www.linkedin.com/in/jorge-andr%C3%A9s-sarmiento-alfaro-56b84294/"
                      className="btn btn-primary btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs"
                      target="_blank"
                    >
                      <div className="avatar-title bg-transparent">
                        <i className="ri-linkedin-fill align-bottom"></i>
                      </div>
                    </Link>
                  </div>

                  {/* <!-- end card body --> */}
                  <h5 className="mb-1">
                    <Link to="/pages-profile" className="text-body">
                      Jorge Sarmiento
                    </Link>
                  </h5>
                  <p className="text-muted mb-0 ff-secondary">CEO - Auditor</p>
                  <p className="text-muted mb-0 ff-secondary">
                    RR. HH. y bienestar
                  </p>
                  <p className="text-muted mb-0 ff-secondary">
                    Asesorías laborales
                  </p>
                </CardBody>
              </Card>

              {/* <!-- end card --> */}
            </Col>

            {/* <!-- end col --> */}
          </Row>

          {/* <!-- end row --> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Team;
