import React from "react";
import { Col, Container, Row } from "reactstrap";

const Cta = () => {
  return (
    <React.Fragment>
      <section className="py-5 bg-primary position-relative">
        <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
        <Container>
          <Row className="align-items-center gy-4">
            <Col className="col-sm">
              <div></div>
            </Col>
            <Col className="col-sm-auto"></Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Cta;
