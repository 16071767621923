import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Services = () => {
  return (
    <React.Fragment>
      <section
        className="section"
        style={{ backgroundColor: "#3e3c85" }}
        id="services"
      >
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg={8}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  ¿BackApp Chile es?
                </h1>
                <p className="text-muted">
                  BackApp es un Multiservicios integral de Administración con el
                  cual podrás dedicar 100% a la operatividad de tu empresa, ya
                  que ofrecemos soluciones de procesos a pymes de Chile.
                  Procesamos todas tus transacciones y mantenemos tu empresa
                  totalmente organizada.
                </p>
                <h3 className="ff-secondary fw-semibold lh-base">
                  Seremos un partner para tu empresa
                </h3>
                <h3 className="ff-secondary fw-semibold">
                  Un asistente siempre
                </h3>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  BackApp Services
                </h1>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={3}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  Emprende
                </h1>
              </div>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-building-2-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">
                      Creacion de Sociedad Empresa en un dia / Escritura Publica
                    </h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-palette-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Gestión Sii</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-community-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Gestión Municipal</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  Outsourcing
                </h1>
              </div>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-pencil-ruler-2-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Contabilidad</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-money-dollar-box-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Tributario (anual/mensual)</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-lightbulb-flash-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Recursos</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-lightbulb-flash-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Administración</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  Asesorías
                </h1>
              </div>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-checkbox-multiple-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Cumplimiento Laboral</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-checkbox-multiple-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Cumplimiento Tributario</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-checkbox-multiple-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Cumplimiento Societario</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-5">
                <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                  Auditorías
                </h1>
              </div>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-currency-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Tax</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-folder-user-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Laborales</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-folder-chart-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Estado</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-folder-info-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Control interno</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <i className="ri-folder-settings-line fs-36"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-18">Supervisión</h5>
                    <p className="text-muted my-3 ff-secondary"></p>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
